import $ from 'jquery';

export default function showOrHidePassword(){

  // login 3P
  $("span.js-show-password-login").off("click").on("click", function(e) {
    showOrHidePasswordLogin ("#inputPassword-desktop", "text", "span.js-show-password-login", "span.js-hide-password-login");
    showOrHidePasswordLogin ("#inputPassword-mobile", "text", "span.js-show-password-login", "span.js-hide-password-login");
  });

  $("span.js-hide-password-login").off("click").on("click", function(e) {
    showOrHidePasswordLogin ("#inputPassword-desktop", "password", "span.js-hide-password-login", "span.js-show-password-login");
    showOrHidePasswordLogin ("#inputPassword-mobile", "password", "span.js-hide-password-login", "span.js-show-password-login");
  });

  function showOrHidePasswordLogin (pw, type, hide, show){
    $(pw).attr("type", type);
    $(hide).hide();
    $(show).show();
  }

  $("span.js-account-show-password-login").off("click").on("click", function(e) {
    $("#oldPassword").attr("type", "text");
    $("#newPassword").attr("type", "text");
    $("#old-password-show").hide();
    $("#new-password-show").hide();
    $("#old-password-hidden").show();
    $("#new-password-hidden").show();
  });

  $("span.js-account-hide-password-login").off("click").on("click", function(e) {
    $("#oldPassword").attr("type", "password");
    $("#newPassword").attr("type", "password");
    $("#new-password-hidden").hide();
    $("#old-password-hidden").hide();
    $("#new-password-show").show();
    $("#old-password-show").show();
  })

  // register
  $("span.js-show-password-register").off("click").on("click", function(e) {
    showOrHidePasswordRegister ("#password1", "text", "span.js-show-password-register", "span.js-hide-password-register");
  });
  $("span.js-hide-password-register").off("click").on("click", function(e) {
    showOrHidePasswordRegister ("#password1", "password", "span.js-hide-password-register", "span.js-show-password-register");
  });

  function showOrHidePasswordRegister (pw1,type, hide, show){
    $(pw1).attr("type", type);
    $(hide).hide();
    $(show).show();
  }

}
